<template>
  <v-app id="inspire">
    <v-app-bar
      v-if="isMobile"
      prominent  
    >
    <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title>CoPilot</v-toolbar-title>

    <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer">
      <v-sheet
      class="pa-4"
      color="grey-lighten-4"
      v-if="!isMobile"
      >
        
        <h1>CoPilot</h1>
      </v-sheet>
      
      <v-divider></v-divider>
      
      <v-list>
        <v-list-item
        v-for="[icon, text, component] in links"
        :key="icon"
        :prepend-icon="icon"
        :title="text"
        link
        @click="activeComponent = component"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-main
      style="background-color: darkgray;"
    >
    <v-card class="pa-3 mx-5 my-3">
      <v-container
      fluid
      >
      <v-row>
        <v-col cols="12">
          <TaskList ref="taskList" v-if="activeComponent == 'TaskList'" />
          <SentEmails v-if="activeComponent == 'SentEmails'" />
          <ResumeUploader v-if="activeComponent == 'ResumeUploader'" />
          <TranscriptUploader v-if="activeComponent == 'TranscriptUploader'" />
        </v-col>
        </v-row>
      </v-container>
    </v-card>
    </v-main>
  </v-app>
</template>

<script setup>
  import { ref } from 'vue'

  const links = [
    ['mdi-inbox-arrow-down', 'Emails', 'TaskList'],
    ['mdi-send', 'Sent Emails', 'SentEmails'],
    ['mdi-file-document-edit-outline', 'Resume', 'ResumeUploader'],
    ['mdi-file-document-outline', 'Transcripts', 'TranscriptUploader'],
  ]

  const drawer = ref(null)
</script>

<script>
import TaskList from './components/EmailList.vue';
import ResumeUploader from './components/ResumeUploader.vue';
import TranscriptUploader from './components/TranscriptUploader.vue';
import SentEmails from './components/SentEmails.vue';


export default {
  name: 'App',
  data: () => ({
      drawer: null,
      activeComponent: 'TaskList',
    }),
  components: {
    TaskList,
    ResumeUploader,
    TranscriptUploader,
    SentEmails,
  },
  methods: {

  },
  computed: {
    isMobile() {
      console.log(this.$vuetify.display.smAndDown);
      return this.$vuetify.display.smAndDown;
    },
  },
};
</script>
